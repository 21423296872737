<template>
	<div class='p50px h100 boxSizing OverflowAuto'>
		<!-- 标题 -->
		<h1>Important Notice</h1>
		<!-- 关闭icon -->
		<i class="el-icon-close" @click="$router.push('/')"></i>
		<!-- 段落 -->
		<p>{{paragraph1}}</p>
		<p>{{paragraph2}}</p>
		<!-- 选择框 -->
		<Checkbox @changeStatus="changeStatusTop" checkBoxType="multiple" :content="checkBoxContent" :checkedData="checkedData" :disabled="true">
		</Checkbox>
		<p>{{paragraph00}}</p>
		<p>{{paragraph01}}</p>
		<p style="margin-top: 5px;" v-html="paragraph02"></p>
		<p>{{paragraph03}}</p>
		<!-- 段落 -->
		<p>{{paragraph3}}</p>
		<p>{{paragraph3_1}}</p>
		<p>{{paragraph4}}</p>
		<h2>Personal data collection statement</h2>
		<p style="margin-top: 10px;" v-html="paragraph5"></p>
		<h2>1. Purpose of collection</h2>
		<p style="margin-top: 10px;">{{paragraph6}}</p>
		<h2>2. Disclosure of personal data</h2>
		<p style="margin-top: 10px;">{{paragraph7}}</p>
		<!-- 单选加确认按钮 -->
		<div class="displayFlex flexColumn flexJCspaceBetween flexAlignCenter mb20px" style="height: 150px;">
			<Checkbox @changeStatus="changeStatus">
				<p>Yes, I have carefully explained this notice to my client(s)</p>
			</Checkbox>
			<el-button type="warning" size="medium" :disabled="!startBtn" @click="$router.push('/BeforeStart')">Start KYC</el-button>
		</div>
	</div>
</template>

<script>
import Checkbox from './components/checkBox.vue'
import { productCommonInfoQuery } from '@/api/Product'
import { GET_username } from '@/utils/localStorage'
export default {
	components: { Checkbox },
	data () {
		return {
			checkBoxContent: [],
			checkedData: [],
			startBtn: false,
			paragraph00: "* Auxiliary services refer to any partnership, joint venture or any other arrangement that the representative might have to carry on," +
			  "the business of carrying out referral activities in respect of any non-financial advisory service(s) e.g. will writing.",
			paragraph01: "Infinitum has entered into distribution agreements with various providers and platforms. After going through a needs-based sales advisory " +
			  "process with the clients, the Company is able to recommend from a wide range of investment products and solutions from various providers and platforms.",
			paragraph02: "Please refer to Infinitum's website at <a href='https://www.infinitumfa.com/services-and-partners.html' target='_blank' style='color: blue;'>https://www.infinitumfa.com/services-and-partners.html</a> " +
				"for a complete listing of product providers Infinitum Financial Advisory Pte Ltd can recommend and/or market on behalf to its clients which may be periodically updated from time to time.",
			paragraph03: "As an Appointed Representative, I earn an income from the various products and financial services arranged for you. This income is earned through commissions, fees, bonuses or any other indirect benefits from the product and service manufacturers or providers.",
			paragraph1: "Infinitum Financial Advisory Pte Ltd ('Infinitum') is a Financial Adviser licensed and regulated under the Financial Advisers Act " +
				"('FAA') to provide a wide range of financial advisory services.These services include advising on investment products, marketing " +
				"of collective investment schemes (CIS) as well as arranging life policies, health insurance and investment-linked plans (ILP).",
			paragraph2: "Financial Services Representatives who are engaged by Infinitum to carry out such services are required to be Appointed " +
				"Representatives under the FAA. The representative serving you in this case is registered with the Monetary Authority of Singapore " +
				"('MAS') and thereby authorized to provide services in the following:",
			paragraph3: "Your financial services representative must have sufficient information from you before making a suitable recommendation.The " +
				"information that you provide about your investment objectives,financial situation and particulars needs will form the basis from " +
				"which suitable advice and recommendation can be made. If there is any change in your circumstances since completing this fact-find " +
				"form, you should also update your financial services representative about the change as it may affect the needs analysis process and " +
				"outcome of the recommendation. If insufficient or inaccurate information is provided in the process,the quality of the advice and recommendation " +
				"given can be adversely affected. We do encourage you to provide sufficient information to the financial services representative.",
			paragraph3_1: "Please be advised that all analysis conducted in respect of your needs in life protection, disability income protection, retirement planning, " +
			  "education planning and wealth management are for your reference only. Formulations of the analysis are estimates only and based on hypothetical assumptions " +
				"and information provided by you. No guarantee or representations can be made that the formulations or assumptions are full and complete at present or in the " +
				"future. The analysis merely provides an estimate of your personal needs based on calculations. It shall not constitute a substitute for professional advice.",
			paragraph4: "We understand and share your concerns over the use of your personal data and wish to assure you that it will be kept in strictest confidence.",
			paragraph5: "Infinitum recognizes its obligations under the Personal Data Protection Act 2012 (PDPA) which include the collection, use and " +
				"disclosure of personal data for the purpose for which an individual has given consent to. The personal data collected by Income includes all personal data " +
				"provided in this form, or in any document provided, or to be provided to us by you or your insured persons or from other sources, for the purpose of this " +
				"insurance application or transaction. It includes all personal data for us to evaluate or administer this application or transaction. For " +
				"further information on our privacy policy, please go to <a href='https://www.infinitumfa.com/policies.html' target='_blank'>https://www.infinitumfa.com/policies.html</a>",
			paragraph6: "We may collect and use the personal data to: (a) communicate on purposes relating to an application; (b) determine and verify your " +
				"creditworthiness for the financial and insurance products you apply for; (c) provide financial advice for product recommendation " +
				"based on your financial needs analysis; (d) provide ongoing services and respond to your inquiries or instructions; (e) coach " +
				"employees and monitor for quality assurance; and (f) comply with all applicable laws, including reporting to regulatory and industry entities.",
			paragraph7: "We may disclose personal data belonging to you and your insured persons for the purposes set out in section 1 above to: (a) your " +
				"representatives; (b) local or overseas service providers to provide us with services such as printing, mail distribution, data " +
 				"storage, data entry; and (c) regulators, law enforcement and government agencies."
		};
	},
	computed: {},
	watch: {},
	methods: {
		changeStatusTop (val) {},
		changeStatus (val) {
			this.startBtn = val
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	async created () {
		// 获取多选框内容
		this.checkBoxContent = []
		this.checkedData = []
		let res = await productCommonInfoQuery({ advisor_code: GET_username() })
		res.content.product_line.map(data => {
			this.checkBoxContent.push(data.product_line_name)
			if (data.qualification === 'Y') this.checkedData.push(data.product_line_name)
		})
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {

	}
}
</script>
<style lang='css' scoped>
h1,
i {
	font-size: 24px;
	color: #6c6c6c;
	font-weight: bold;
}
i {
	position: fixed;
	right: 50px;
	top: 30px;
	cursor: pointer;
}
h2 {
	font-size: 22px;
	color: #6c6c6c;
	line-height: 16px;
	margin-top: 30px;
}
p {
	margin-top: 30px;
	font-size: 18px;
	color: #707070;
	line-height: 24px;
	letter-spacing: 0px;
}

</style>